<template>
  <div>
    <div class="home">
      <div class="row mb-20">
        <div class="col">
          <chart
            v-if="GetterLastWeekPaymentsByMethod.length > 1"
            :dataset="GetterLastWeekPaymentsByMethod"
            title="Conversões do último mês"
            type="BarChart"
            subTitle="Testando"
            class="chart"
            :extraOptions="{
              legend: 'bottom',
              chartArea:{
                height: 900,
              },
              height: 1050,
              tooltip:{
                trigger: 'focus',
              }
            }"
          />
        </div>
      </div>

      <span class="section-title">Postbacks não processados</span>
      <div class="stat-container">
        <div class="form-row mb-20">
          <div class="col-sm-3 col-12">
            <div class="conversion-rate-box">
              <h4>Não processados</h4>
              <div class="conversion-rate-wrapper">
                <span class="conversion-rate">
                  <span>{{ notProcessed.receivedPostsNotProcessed }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-9 col-12">
            <div class="graph-box">
              <chart
                v-if="GetterNotProcessedPerPlatform.length > 1"
                :dataset="GetterNotProcessedPerPlatform"
                title="Não processados por operadora"
                type="ColumnChart"
                subTitle="Testando"
                class="chart"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="stat-container">
        <div class="form-row mb-20">
          <div class="col-sm-3 col-12">
            <div class="conversion-rate-box">
              <h4>Não processados hoje</h4>
              <div class="conversion-rate-wrapper">
                <span class="conversion-rate">
                  <span>{{ notProcessed.receivedPostsNotProcessedToday }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-9 col-12">
            <div class="graph-box">
              <chart
                v-if="GetterNotProcessedPerPlatformToday.length > 1"
                :dataset="GetterNotProcessedPerPlatformToday"
                title="Não processados por operadora hoje"
                type="ColumnChart"
                subTitle="Testando"
                class="chart"
              />
            </div>
          </div>
        </div>
      </div>

      <span class="section-title">Produtos</span>
      <div
        class="stat-container"
        v-for="(chargesSummary, idx) in GetterChargesPerProduct"
        :key="idx"
      >
        <div class="form-row mb-20">
          <div class="col-sm-3 col-12">
            <div class="conversion-rate-box">
              <h4>Taxa de conversão</h4>
              <div class="conversion-rate-wrapper">
                <span class="conversion-rate">
                  <span>{{ chargesSummary.conversionPercentage }}% </span>
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-9 col-12">
            <div class="graph-box">
              <chart
                :dataset="chargesSummary.chargesSummary"
                :title="`Estatística do produto ${chargesSummary.productName}`"
                type="ColumnChart"
                subTitle="Testando"
                class="chart"
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script type="text/javascript">
import { mapActions, mapGetters, mapState } from 'vuex';
import Chart from '@/components/charts/StandardChart.vue';

export default {
  components: {
    Chart,
  },
  data() {
    return {
      users: [],
    };
  },
  computed: {
    ...mapState('dashboard', ['notProcessed']),
    ...mapGetters('dashboard', [
      'GetterLastWeekPaymentsByMethod',
      'GetterChargesPerProduct',
      'GetterNotProcessedPerPlatform',
      'GetterNotProcessedPerPlatformToday',
    ]),
  },
  mounted() {
    this.ActionGetLastWeekPaidChargesByPaymentMethods();
    this.ActionGetChargesPerProduct();
    this.ActionGetReceivedPostsNotProcessed();
  },
  methods: {
    ...mapActions('dashboard', [
      'ActionGetLastWeekPaidChargesByPaymentMethods',
      'ActionGetChargesPerProduct',
      'ActionGetReceivedPostsNotProcessed',
    ]),
  },
};
</script>
<style lang="scss">
.chart {
  width: 100%;
}
.section-title {
  display: block;
  background-color: var(--standard-color);
  color: white;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}
.stat-container{
  background-color: white;
.conversion-rate-box {
  background-color: white;
  padding: 10px;
  height: 250px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h4 {
    text-align: center;
    font-size: 1.2rem;
  }
  .conversion-rate-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .conversion-rate {
      display: flex;
      height: 130px;
      width: 130px;
      border: 10px solid var(--standard-color);
      border-radius: 50%;
      padding: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        font-size: 2rem;
      }
    }
  }
}
.graph-box {
  background-color: white;
  height: 250px;
}
}
</style>
