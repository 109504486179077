<template>
  <div class='chart'>
    <span class="title">{{title}}</span>
    <GChart
    v-if='showChart'
    :type="type"
    :data="dataset"
    :options="chartOptions"
    :resizeDebounce="500"
    />
  </div>
</template>
<script>
import { GChart } from 'vue-google-charts';

export default {
  components: {
    GChart,
  },
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'LineChart',
      validator(value) {
        return ['LineChart', 'ColumnChart', 'BarChart'].indexOf(value) !== -1;
      },
    },
    extraOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showChart: false,
      // Array will be automatically processed with visualization.arrayToDataTable function
    };
  },
  computed: {
    chartOptions() {
      return {
        title: '',
        subtitle: '',
        legend: 'none',
        annotations: {
          textStyle: {
            fontName: 'Times-Roman',
            fontSize: 20,
            bold: true,
            italic: true,
          },
        },
        ...this.extraOptions,
      };
    },
  },
  mounted() {
    this.showChart = true;
  },
};
</script>
<style scoped lang='scss'>
.chart{
  background-color: white;
  padding:10px;
  .title {
    display:block;
    text-align:center;
    font-weight: 500;
  }
}
</style>
